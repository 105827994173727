import { ethers } from 'ethers'
import { BLOCKS_CORE_ADDRESS } from 'base/dotenv'
import _TilesBlocksCore from '../abi/ERC721Mock.json'

export class BlocksCoreContract {
  constructor(signer) {
    this.contract = new ethers.Contract(
      BLOCKS_CORE_ADDRESS,
      _TilesBlocksCore.abi,
      signer
    )
  }
  get() {
    return this.contract
  }
}
