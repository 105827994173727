import { BigNumber, ethers } from 'ethers'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { STATUS } from 'utils/status'

export function formatUnitToNumber(value: BigNumber) {
  return ethers.utils.formatUnits(value, 0)
}

interface BaseFetchProps {
  contract: ethers.Contract | null
  userAddress: string
}

type FetchDepositInfoReturn = {
  info: {
    balance: number
    balanceFormatted: number
    principal: number
    principalTotal: number
  }
  PRECISION: number
  price: number
}

const depositInfoInitialState = {
  info: {
    balance: 0,
    balanceFormatted: 0,
    principal: 0,
    principalTotal: 0,
  },
  PRECISION: 0,
  price: 0,
}

type ContractInfoReturn = {
  balance: BigNumber
  balanceFormatted: BigNumber
  principal: BigNumber
  principalTotal: BigNumber
}
export const fetchDepositInfo = createAsyncThunk(
  'blocks/fetchDepositInfoStatus',
  async ({
    contract,
    userAddress,
  }: BaseFetchProps): Promise<FetchDepositInfoReturn> => {
    if (!userAddress || !contract) return depositInfoInitialState
    try {
      const PRECISION = (await contract.PRECISION()) as BigNumber
      const price = (await contract.depositPrice()) as BigNumber
      const isAllowedList =
        (await contract.allowedList(userAddress)).toNumber() > 0
      const {
        balance,
        balanceFormatted,
        principal,
        principalTotal,
      }: ContractInfoReturn = await contract.getFullBalance(userAddress)

      const divisorNumber = 1000
      return {
        info: {
          balance:
            balance.div(PRECISION.div(divisorNumber)).toNumber() /
            divisorNumber,
          balanceFormatted: balanceFormatted.toNumber(),
          principal: principal.div(PRECISION).toNumber(),
          principalTotal: principalTotal.div(PRECISION).toNumber(),
        },
        PRECISION: PRECISION.toNumber(),
        price: isAllowedList ? 0 : Number(ethers.utils.formatEther(price)),
      }
    } catch (err) {
      console.log('fetchDepositInfo error: ', err)
      return depositInfoInitialState
    }
  },
  {
    condition: (_, { getState }) => {
      const { deposit } = getState() as RootState
      if (deposit.infoStatus === STATUS.PENDING) {
        return false
      }
    },
  }
)
