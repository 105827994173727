import { createContext, useRef } from 'react'

const DEFAULT_CONTEXT = {
  navRef: null,
  footerRef: null,
}

const GlobalContext = createContext(DEFAULT_CONTEXT)

export const GlobalContextProvider = ({ children }) => {
  const navRef = useRef()
  const footerRef = useRef()
  return (
    <GlobalContext.Provider
      value={{
        ...DEFAULT_CONTEXT,
        navRef,
        footerRef,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
