import { useContext } from 'react'
import { ReportIssue } from 'components/organisms/ReportIssue'
import { MyDepositInfo } from 'components/organisms/MyDepositInfo'
import GlobalContext from 'contexts/GlobalContext'
import { useWallet } from 'hooks/useWallet'
import { HowWithdrawWorks } from 'components/organisms/HowWithdrawWorks'
import { WithdrawBlocks } from 'components/organisms/WithdrawBlocks'

export const Withdraw = () => {
  const { footerRef } = useContext(GlobalContext)
  const { moveIsApproved, onMoveApproval } = useWallet()

  return (
    <>
      {moveIsApproved && <MyDepositInfo />}
      <HowWithdrawWorks />
      <WithdrawBlocks
        isMoveApproved={moveIsApproved}
        onMoveApproval={onMoveApproval}
        navRef={footerRef}
      />
      <ReportIssue />
    </>
  )
}
