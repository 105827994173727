import { useContext, useEffect } from 'react'
import { Box, SimpleGrid, Text } from '@chakra-ui/react'
import EthersContext from 'contexts/EtherInstances'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { fetchDepositInfo } from 'services/deposit'

export const MyDepositInfo = () => {
  const { tradableContractSigned, userAddress } = useContext(EthersContext)
  const dispatch = useAppDispatch()
  const depositInfo = useAppSelector(({ deposit }) => ({
    balance: deposit.balance,
    balanceFormatted: deposit.balanceFormatted,
    principal: deposit.principal,
    principalTotal: deposit.principalTotal,
  }))
  const blocksVaultUpdated = useAppSelector(
    (state) => state.vaultBlocks.listUpdated
  )
  const myBlocksUpdated = useAppSelector((state) => state.myBlocks.listUpdated)

  useEffect(() => {
    if (blocksVaultUpdated || myBlocksUpdated) {
      dispatch(
        fetchDepositInfo({ contract: tradableContractSigned, userAddress })
      )
    }
  }, [
    blocksVaultUpdated,
    dispatch,
    myBlocksUpdated,
    tradableContractSigned,
    userAddress,
  ])

  useEffect(() => {
    dispatch(
      fetchDepositInfo({ contract: tradableContractSigned, userAddress })
    )
  }, [dispatch, tradableContractSigned, userAddress])

  if (depositInfo.balance === 0) return <></>
  return (
    <Box w="full" py="4" bg="blackAlpha.800">
      <Box maxW={{ base: '100%', md: '7xl' }} mx="auto">
        <SimpleGrid columns={{ base: 2, md: 4 }} spacing={{ base: 6, md: 0 }}>
          <Box textAlign="center" color="white">
            <Text>Current Balance</Text>
            <Text fontSize={{ base: '2xl', md: '3xl' }}>
              {depositInfo.balance}
            </Text>
          </Box>
          <Box textAlign="center" color="white">
            <Text>Current Share</Text>
            <Text fontSize={{ base: '2xl', md: '3xl' }}>
              {depositInfo.principal}/{depositInfo.principalTotal}
            </Text>
          </Box>
          <Box textAlign="center" color="white">
            <Text>Deposited Blocks</Text>
            <Text fontSize={{ base: '2xl', md: '3xl' }}>
              {depositInfo.principal}
            </Text>
          </Box>
          <Box textAlign="center" color="white">
            <Text>Blocks Profit</Text>
            <Text fontSize={{ base: '2xl', md: '3xl' }}>
              {Math.floor(depositInfo.balance - depositInfo.principal)}
            </Text>
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  )
}
