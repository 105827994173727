import { Text, TextProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

const getColor = (isValid: Boolean) => (isValid ? 'white' : 'brand.500')

type DinamycInfoTextProps = {
  isValid: Boolean
  children: ReactNode
} & TextProps

export const DinamycInfoText = ({
  isValid,
  children,
  ...props
}: DinamycInfoTextProps) => (
  <Text
    textColor={getColor(isValid)}
    as="span"
    fontStyle={'italic'}
    fontWeight={'bold'}
    {...props}
  >
    {children}
  </Text>
)
