import { Box, Heading, Icon, IconProps } from '@chakra-ui/react'
import { AiOutlineBlock } from 'react-icons/ai'

export const Logo = (props: IconProps) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Icon as={AiOutlineBlock} color="brand.500" {...props} h="7" />
      <Heading size={'mg'} ml="2" color={'brand.500'}>
        Trade Blocks
      </Heading>
    </Box>
  )
}
