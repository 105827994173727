import { createSlice } from '@reduxjs/toolkit'
import { fetchVaultBlocks } from 'services/blocks'
import { STATUS } from 'utils/status'

export interface VaultBlocksState {
  list: number[]
  error: string | null | unknown
  status: STATUS
  listUpdated: boolean
}

const initialState: VaultBlocksState = {
  list: [],
  error: null,
  status: STATUS.IDLE,
  listUpdated: false,
}

export const vaultBlocksSlice = createSlice({
  name: 'vaultBlocks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVaultBlocks.fulfilled, (state, action) => {
      if (
        state.status !== STATUS.IDLE &&
        state.list.length > 0 &&
        state.list.length !== action.payload.length
      ) {
        state.listUpdated = true
      }
      state.list = action.payload
      state.status = STATUS.SUCCESS
    })
    builder.addCase(fetchVaultBlocks.pending, (state) => {
      state.listUpdated = false
      state.status = STATUS.PENDING
    })
  },
})

// Action creators are generated for each case reducer function
// export const {  } = myBlocksSlice.actions

export default vaultBlocksSlice.reducer
