import { Box, Heading, Link, List, ListIcon, ListItem } from '@chakra-ui/react'
import { OPENSEA_URL, TRADABLE_CORE_ADDRESS } from 'base/dotenv'
import { AiOutlineRightSquare } from 'react-icons/ai'

export const HowWithdrawWorks = () => {
  return (
    <Box my={20} id="HowWithdrawWorks">
      <Heading size="2xl" textAlign={'center'}>
        How does withdraw works?
      </Heading>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        maxW={'container.lg'}
        px="5"
        mx={'auto'}
        mt={'10'}
      >
        <Box w={{ base: '100%' }} mb={10}>
          <List spacing={3} textAlign={'left'} w="80%" mx="auto">
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You can withdraw any amount of Blocks any time you want. As long
              you take them all or let at least 1 Block deposited (Deposited
              Blocks stat above).
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              All withdraws are taken from your deposit balance (deposited
              Blocks). Therefore, it will never decrease your profit balance.
              You must let at least 1 deposited Block or withdraw all your
              Blocks at one time.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              There is no fee for withdraw, only pay the gas fee.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You can check all Blocks and rarity on{' '}
              <Link
                isExternal
                href={`${OPENSEA_URL}${TRADABLE_CORE_ADDRESS}`}
                textDecor={'underline'}
              >
                OpenSea
              </Link>
              .
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              The rule is first come, first served. Be sure the Blocks are on TB
              Vault, and you execute your transaction quickly. Unfortunately, we
              can't help with gas fee losses with failed transactions.
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  )
}
