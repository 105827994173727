import { useContext } from 'react'
import { ReportIssue } from 'components/organisms/ReportIssue'
import { MyDepositInfo } from 'components/organisms/MyDepositInfo'
import { DepositBlocks } from 'components/organisms/DepositBlocks'
import { HowDepositWorks } from 'components/organisms/HowDepositWorks'
import GlobalContext from 'contexts/GlobalContext'
import { useWallet } from 'hooks/useWallet'

export const Deposit = () => {
  const { footerRef } = useContext(GlobalContext)
  const { moveIsApproved, onMoveApproval } = useWallet()

  return (
    <>
      {moveIsApproved && <MyDepositInfo />}
      <HowDepositWorks />
      <DepositBlocks
        isMoveApproved={moveIsApproved}
        onMoveApproval={onMoveApproval}
        navRef={footerRef}
      />
      <ReportIssue />
    </>
  )
}
