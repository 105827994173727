import { Activities } from 'components/organisms/Activities'
import { FAQ } from 'components/organisms/FAQ'
import { Hero } from 'components/molecules/Hero'
import { HowItWorks } from 'components/organisms/HowItWorks'
import { ReportIssue } from 'components/organisms/ReportIssue'

export const Home = () => (
  <>
    <Hero />
    <HowItWorks />
    <Activities />
    <FAQ />
    <ReportIssue />
  </>
)
