import { useCallback, useEffect } from 'react'
import { useToast, UseToastOptions } from '@chakra-ui/react'
import { depositSlice } from 'features/blocks/depositSlice'
import { myBlocksSlice } from 'features/blocks/myBlocksSlice'
import { withdrawSlice } from 'features/blocks/withdrawSlice'
import { useAppDispatch, useAppSelector } from './redux'

enum TOAST_IDS {
  WALLET_UPDATE = 'blocks-wallet-updated',
  VAULT_UPDATE = 'blocks-vault-updated',
}

type getToastProps = {
  id: TOAST_IDS
}

type contentObj = {
  [key in TOAST_IDS]: UseToastOptions
}

const getToast = ({ id }: getToastProps): UseToastOptions | void => {
  const toastDefaultOptions = {
    description: 'If you had Blocks selected, please, select them again.',
    // status: 'info',
    duration: 3000,
    isClosable: true,
    id,
  }
  const content: contentObj = {
    [TOAST_IDS.WALLET_UPDATE]: {
      ...toastDefaultOptions,
      title: 'Your wallet Blocks has been updated!',
    },
    [TOAST_IDS.VAULT_UPDATE]: {
      ...toastDefaultOptions,
      title: 'TB Vault Blocks has been updated!',
    },
  }
  if (content[id]) return content[id]
  return
}

export const useToastUpdate = () => {
  const dispatch = useAppDispatch()
  const toast = useToast()

  const blocksWalletUpdated = useAppSelector(
    (state) => state.myBlocks.listUpdated
  )
  const blocksVaultUpdated = useAppSelector(
    (state) => state.vaultBlocks.listUpdated
  )

  const triggerToast = useCallback(
    (toastId: TOAST_IDS) => {
      if (toast.isActive(toastId)) return
      const toastOptions = getToast({ id: toastId })
      if (toastOptions) toast(toastOptions)
    },
    [toast]
  )

  useEffect(() => {
    if (blocksWalletUpdated) {
      dispatch(myBlocksSlice.actions.clear())
      triggerToast(TOAST_IDS.WALLET_UPDATE)
    }
  }, [blocksWalletUpdated, dispatch, triggerToast])

  useEffect(() => {
    if (blocksVaultUpdated) {
      dispatch(withdrawSlice.actions.clear())
      dispatch(depositSlice.actions.clear())
      triggerToast(TOAST_IDS.VAULT_UPDATE)
    }
  }, [blocksVaultUpdated, dispatch, triggerToast])
}
