import {
  Image,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  Link,
} from '@chakra-ui/react'
import { MouseEventHandler } from 'react'

type CartItemProps = {
  name: string
  imageUrl: string
  onClickDelete?: MouseEventHandler<HTMLAnchorElement>
}

export const CartItem = ({ name, imageUrl, onClickDelete }: CartItemProps) => {
  const color = useColorModeValue('brand.500', 'brand.200')
  return (
    <Stack direction="row" spacing="5">
      <Image
        minWidth="24"
        maxWidth="24"
        height={{ base: '20', md: '24' }}
        fit="cover"
        src={imageUrl}
        alt={name}
        draggable="false"
        loading="lazy"
      />
      <Stack width="full" spacing="3">
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing="3"
          alignItems="flex-start"
        >
          <Stack spacing="0.5" width="full">
            <Text fontWeight="medium">{name}</Text>
          </Stack>
        </Stack>
        {onClickDelete && (
          <Flex width="full" justifyContent="space-between" alignItems="center">
            <Link
              as="button"
              type="button"
              fontWeight="medium"
              fontSize="sm"
              color={color}
              onClick={onClickDelete}
            >
              Remove
            </Link>
          </Flex>
        )}
      </Stack>
    </Stack>
  )
}
