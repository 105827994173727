import { ElementType, ReactNode } from 'react'
import {
  HStack,
  useColorModeValue,
  Icon,
  Text,
  HTMLChakraProps,
  Box,
} from '@chakra-ui/react'
import { GoPrimitiveDot } from 'react-icons/go'

interface HStackItemListProps extends HTMLChakraProps<'div'> {
  icon?: ElementType
  children: ReactNode
}

export const HStackItemList = ({
  children,
  icon = GoPrimitiveDot,
  ...props
}: HStackItemListProps) => (
  <HStack
    color={useColorModeValue('gray.600', 'gray.400')}
    alignItems={'baseline'}
    {...props}
  >
    <Box>
      <Icon as={icon} />
    </Box>
    <Text>{children}</Text>
  </HStack>
)
