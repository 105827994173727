import { BigNumber, ethers } from 'ethers'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { fetchTransferToVault } from 'services/blocks'
import { fetchDepositInfo } from 'services/deposit'
import { RootState } from 'store'
import { STATUS } from 'utils/status'

const blocksAdapter = createEntityAdapter<{ id: number }>()

export function formatUnitToNumber(value: BigNumber | string) {
  return ethers.utils.formatUnits(value, 0)
}

export function formatUnitToEther(value: BigNumber) {
  return ethers.utils.formatEther(value)
}

export const depositSlice = createSlice({
  name: 'deposit',
  initialState: blocksAdapter.getInitialState({
    status: STATUS.IDLE,
    infoStatus: STATUS.IDLE,
    balance: 0,
    balanceFormatted: 0,
    principal: 0,
    principalTotal: 0,
    price: 0,
  }),
  reducers: {
    add: blocksAdapter.addOne,
    remove: blocksAdapter.removeOne,
    clearList: blocksAdapter.removeAll,
    clear: (state) => {
      state.status = STATUS.IDLE
      blocksAdapter.removeAll(state)
    },
    clearError: (state) => {
      state.status = STATUS.IDLE
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransferToVault.pending, (state) => {
      state.status = STATUS.PENDING
    })
    builder.addCase(fetchTransferToVault.fulfilled, (state) => {
      state.status = STATUS.SUCCESS
    })
    builder.addCase(fetchTransferToVault.rejected, (state) => {
      state.status = STATUS.ERROR
    })
    builder.addCase(fetchDepositInfo.pending, (state) => {
      state.infoStatus = STATUS.PENDING
    })
    builder.addCase(fetchDepositInfo.rejected, (state) => {
      state.infoStatus = STATUS.ERROR
    })
    builder.addCase(fetchDepositInfo.fulfilled, (state, action) => {
      const { info, price } = action.payload
      const { balance, balanceFormatted, principal, principalTotal } = info

      state.balance = balance
      state.balanceFormatted = balanceFormatted
      state.principal = principal
      state.principalTotal = principalTotal
      state.infoStatus = STATUS.SUCCESS
      state.price = price
    })
  },
})

export const blocksSelectors = blocksAdapter.getSelectors<RootState>(
  (state) => state.deposit
)

export default depositSlice.reducer
