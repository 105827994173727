import { Stack, StackItem, Heading } from '@chakra-ui/react'
import { BlocksList, BlocksListType } from 'components/molecules/BlockList'

interface BlockListSectionProps extends BlocksListType {
  title: string
}

export const BlockListSection = ({
  blocks,
  onSelectBlock,
  selectedBlocks,
  title = '',
}: BlockListSectionProps) => (
  <Stack py={10} px={8} spacing={10} w={{ base: '100%', md: '50%' }}>
    <StackItem textAlign={'center'}>
      <Heading size={'lg'}>{title}</Heading>
    </StackItem>
    <StackItem
      maxH={'1500px'}
      overflowY={'scroll'}
      border={'2px solid black'}
      borderColor={'gray.200'}
      p={5}
    >
      <BlocksList
        blocks={blocks}
        onSelectBlock={onSelectBlock}
        selectedBlocks={selectedBlocks}
        columns={{ base: 3, md: 3, lg: 4, xl: 5 }}
      />
    </StackItem>
  </Stack>
)
