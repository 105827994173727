import { ReactNode, useContext, useEffect, useState } from 'react'
import { ConnectButton } from 'components/atoms/ConnectButton'
import EthersContext from 'contexts/EtherInstances'

type WalletIsConnectedProps = {
  children: ReactNode
  onlyAdmin?: boolean
}

export const WalletIsConnected = ({
  children,
  onlyAdmin,
}: WalletIsConnectedProps) => {
  const [adminAddress, setAdminAddress] = useState(null)
  const {
    onConnectWallet,
    walletIsConnected,
    userAddress,
    tradableContractRead,
  } = useContext(EthersContext)

  useEffect(() => {
    async function init() {
      const address = await tradableContractRead.adminAddress()
      setAdminAddress(address)
    }
    init()
  }, [tradableContractRead, userAddress])
  if (onlyAdmin && (!adminAddress || adminAddress !== userAddress)) {
    return <>Only admin</>
  }
  if (walletIsConnected) return <>{children}</>
  return <ConnectButton onClick={onConnectWallet} />
}
