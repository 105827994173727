import { configureStore } from '@reduxjs/toolkit'
import depositReducer from 'features/blocks/depositSlice'
import withdrawReducer from 'features/blocks/withdrawSlice'
import myBlocksReducer from 'features/blocks/myBlocksSlice'
import vaultBlocksReducer from 'features/blocks/vaultBlocksSlice'

export const store = configureStore({
  reducer: {
    myBlocks: myBlocksReducer,
    vaultBlocks: vaultBlocksReducer,
    deposit: depositReducer,
    withdraw: withdrawReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
