import { useEffect, useState } from 'react'
import { HTMLChakraProps } from '@chakra-ui/system'
import {
  Box,
  HStack,
  Link,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { ETHERSCAN_URL } from 'base/dotenv'

dayjs.extend(relativeTime)

interface MessageProps extends HTMLChakraProps<'div'> {
  address: string
  blocks: number
}

interface FeatureProps extends MessageProps {
  icon: React.ElementType
  title: string
  txId: string
  timestamp: number
}

const Message = ({ blocks, address, ...otherProps }: MessageProps) => {
  return (
    <Text {...otherProps}>
      <Text as="span" fontWeight={'bold'}>
        {blocks}
      </Text>{' '}
      Blocks from{' '}
      <Text as="span" fontWeight={'bold'}>
        {address}
      </Text>
    </Text>
  )
}

export const Activity = ({
  icon,
  title,
  blocks,
  address,
  txId,
  timestamp,
}: FeatureProps) => {
  const [time, setTime] = useState('loading...')
  useEffect(() => {
    setTime(dayjs().to(dayjs(timestamp * 1000)))
  }, [timestamp])
  return (
    <Box>
      <Box
        color={mode('brand.500', 'brand.300')}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        pr={6}
      >
        <HStack spacing="3">
          <Box as={icon} fontSize="xl" />
          <Link isExternal href={`${ETHERSCAN_URL}tx/${txId}`}>
            <Text fontWeight="bold" fontSize="lg">
              {title}
              <ExternalLinkIcon mx="4px" mt={-1} fontSize={'sm'} />
            </Text>
          </Link>
        </HStack>
        <Text fontSize="sm">{time}</Text>
      </Box>
      <Message mt="2" blocks={blocks} address={address} />
    </Box>
  )
}
