import { useContext, useEffect, useState } from 'react'
import { Box, Button, Stack, StackItem, Text } from '@chakra-ui/react'
import EthersContext from 'contexts/EtherInstances'
import { WalletIsConnected } from 'components/organisms/WalletIsConnected'
import { BigNumber, ethers } from 'ethers'

function getRandomArbitrary(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min)
}

export const MintButton = () => {
  const [ownerBalance, setOwnerBalance] = useState(0)
  const [etherBalance, setEtherBalance] = useState(0)
  const { blocksContractSigned, provider, tradableContractSigned } =
    useContext(EthersContext)

  const onMintBlocks = async () => {
    const value = window.prompt(
      'Set mint start id',
      getRandomArbitrary(100, 2000).toString()
    )
    if (!value) return
    const address = window.prompt(
      'Address',
      '0x13d735A4D5E966b8F7B19Fc2f476BfC25c0fc7Dc'
    )
    if (!address) return
    console.table({
      address,
      n: 50,
      start: parseInt(value, 10),
    })
    await blocksContractSigned.testMintMany(address, 50, parseInt(value, 10))
  }
  const onSetDepositFee = async () => {
    const value = window.prompt('Fee', '0')
    if (!value) return

    await tradableContractSigned.setDepositPrice(ethers.utils.parseEther(value))
  }
  const onWithdraw = async () => {
    try {
      console.log(await tradableContractSigned.withdrawEther())
    } catch (e) {
      console.log(e)
    }
  }
  const onWithdrawBlocks = async () => {
    const value = window.prompt('Ids', '')
    if (!value) return
    const ids = value.split(',')
    if (ids.length < 1) return
    console.log(await tradableContractSigned.withdrawAdminNFTs(ids))
  }
  const onSetMaxLimit = async () => {
    const value = window.prompt('Set max', '25')
    if (!value) return
    try {
      console.log(await tradableContractSigned.setTransactionLimit(value))
    } catch (e) {
      console.log(e)
    }
  }
  const onAddToAllowedList = async () => {
    const addresses = window.prompt('addresses', '')
    const limit = window.prompt('limit', '')
    if (!addresses || !limit) return
    try {
      console.log(
        await tradableContractSigned.addToAllowedList(
          addresses.split(',').map((address) => address.trim()),
          limit
        )
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    async function init() {
      const balance =
        (await tradableContractSigned.getOwnerBalance()) as BigNumber
      const PRECISION = (await tradableContractSigned.PRECISION()) as BigNumber
      setOwnerBalance(balance.toNumber() / PRECISION.toNumber())
    }
    init()
  }, [tradableContractSigned])

  useEffect(() => {
    async function init() {
      // if (!userAddress) return
      try {
        const balance = await provider.getBalance(
          tradableContractSigned.address
        )
        setEtherBalance(Number(ethers.utils.formatEther(balance)))
      } catch (error) {
        console.log(error)
      }
    }
    init()
  }, [provider, tradableContractSigned.address])

  return (
    <Box
      textAlign={'center'}
      display="flex"
      alignItems={'center'}
      justifyContent={'center'}
      minH={'80vh'}
    >
      <WalletIsConnected onlyAdmin>
        <Stack py={10} px={8} spacing={10}>
          <StackItem textAlign={'center'}>
            <Text>Blocks Balance: {ownerBalance}</Text>
            <Text>Ether Balance: {etherBalance}</Text>
          </StackItem>
          <StackItem textAlign={'center'}>
            <Button
              backgroundColor={'black'}
              color={'white'}
              colorScheme={'brand'}
              size="lg"
              fontSize="md"
              onClick={onMintBlocks}
            >
              Mint 50 blocks
            </Button>
          </StackItem>
          <StackItem textAlign={'center'}>
            <Button
              backgroundColor={'black'}
              color={'white'}
              colorScheme={'brand'}
              size="lg"
              fontSize="md"
              onClick={onSetDepositFee}
            >
              Set deposit fee
            </Button>
          </StackItem>
          <StackItem textAlign={'center'}>
            <Button
              backgroundColor={'black'}
              color={'white'}
              colorScheme={'brand'}
              size="lg"
              fontSize="md"
              onClick={onWithdraw}
            >
              Withdraw Ether
            </Button>
          </StackItem>
          <StackItem textAlign={'center'}>
            <Button
              backgroundColor={'black'}
              color={'white'}
              colorScheme={'brand'}
              size="lg"
              fontSize="md"
              onClick={onWithdrawBlocks}
            >
              Withdraw Blocks
            </Button>
          </StackItem>
          <StackItem textAlign={'center'}>
            <Button
              backgroundColor={'black'}
              color={'white'}
              colorScheme={'brand'}
              size="lg"
              fontSize="md"
              onClick={onSetMaxLimit}
            >
              Change tx max limit
            </Button>
          </StackItem>
          <StackItem textAlign={'center'}>
            <Button
              backgroundColor={'black'}
              color={'white'}
              colorScheme={'brand'}
              size="lg"
              fontSize="md"
              onClick={onAddToAllowedList}
            >
              Add to allowed list
            </Button>
          </StackItem>
        </Stack>
      </WalletIsConnected>
    </Box>
  )
}
