import { Box, Heading, List, ListIcon, ListItem, Text } from '@chakra-ui/react'
import { AiOutlineRightSquare } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'hooks/redux'
import urls from 'base/paths'

export const HowItWorks = () => {
  const price = useAppSelector((state) => state.deposit.price)

  return (
    <Box my={40} id="howItWorks">
      <Heading size="2xl" textAlign={'center'}>
        How does it works?
      </Heading>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        maxW={'container.lg'}
        px="5"
        mx={'auto'}
        mt={'10'}
      >
        <Box w={{ base: '100%', md: '45%' }} mb={10}>
          <Heading my={5} textAlign={'center'}>
            Trade
          </Heading>
          <List spacing={3} textAlign={'left'}>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You can trade your Blocks for any Block from TB vault.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              There is a small fee of 1 Block to recompensate who deposited
              their Blocks to let people trade.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You choose the Blocks you want to trade from your wallet and from
              TB vault.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You can trade till 25 Blocks per transaction, counting offered and
              requested Blocks. Then, you can change 13 from your wallet for 12
              from TB vault.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              The fee for trading will always be the same, 1 Block.
            </ListItem>
            <ListItem fontStyle={'italic'}>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              Why have I to pay a Block fee? See the next box or{' '}
              <Link to={`${urls.home}#faq`}>FAQ</Link> section.
            </ListItem>
          </List>
        </Box>
        <Box w={{ base: '100%', md: '45%' }}>
          <Heading my={5} textAlign={'center'}>
            Deposit
          </Heading>
          <List spacing={3} textAlign={'left'}>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="blue.600" />
              Provide liquidity to Blocks trading.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="blue.600" />
              Support the community building amazing Panels.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              Deposit the Blocks that you won't use now and get a profit from
              each trade and deposit.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              Get a percentage based on your deposited Blocks for the total
              deposited Blocks in each trade or new deposit. <br />
              <Text
                as="span"
                fontStyle={'italic'}
                fontWeight={'bold'}
                fontSize={'sm'}
              >
                If you have 10 Blocks deposited and the TB vault has 20 in
                total, you get 10/20 from the following trade/deposit = 50%.
                When it accumulates enough to get 1(100%) piece, you have it in
                your TB balance.
              </Text>
            </ListItem>
            {/* <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You can withdraw Blocks from the TB vault at any time.
            </ListItem> */}
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You can choose the more exciting Blocks, any time, from the vault
              and get them according to your balance{' '}
              <Text as="span" fontStyle={'italic'}>
                (see withdraw/deposit page)
              </Text>
              .
            </ListItem>
            {/* <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You can deposit or withdraw till 25 Blocks per transaction.
            </ListItem> */}
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              There is no fee for withdrawing, for ever.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              The fee for depositing will always 1 Block + ether amount(now is Ξ
              {price}). Ether amount can change in the future without notice.
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  )
}
