import { ethers } from 'ethers'
import { TRADABLE_CORE_ADDRESS } from 'base/dotenv'
import _TradableBlocks from '../abi/TradableNFTs.json'

export class TradableContract {
  constructor(signer) {
    this.contract = new ethers.Contract(
      TRADABLE_CORE_ADDRESS,
      _TradableBlocks.abi,
      signer
    )
  }
  get() {
    return this.contract
  }
}
