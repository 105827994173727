import {
  MouseEventHandler,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import {
  Box,
  Button,
  Collapse,
  Heading,
  Portal,
  Stack,
  StackItem,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { TRADABLE_CORE_ADDRESS } from 'base/dotenv'
import { BlocksList } from 'components/molecules/BlockList'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { depositSlice } from 'features/blocks/depositSlice'
import { STATUS } from 'utils/status'
import { fetchVaultBlocks, fetchWithdraw } from 'services/blocks'
import EthersContext from 'contexts/EtherInstances'
import { CartDrawer } from '../CartDrawer'
import { HStackItemList } from 'components/atoms/HStackItemList'
import { withdrawSlice } from 'features/blocks/withdrawSlice'
import { useRefreshBlocks } from 'hooks/useRefreshBlocks'
import { useToastUpdate } from 'hooks/useToastUpdate'

const BLOCKS_SELECTED_LIMIT = 25

interface WithdrawBlocksProps {
  isMoveApproved: Boolean
  onMoveApproval: MouseEventHandler<HTMLButtonElement>
  navRef: RefObject<HTMLElement | null> | undefined | null
}

export const WithdrawBlocks = ({ navRef }: WithdrawBlocksProps) => {
  useRefreshBlocks(true, false)
  useToastUpdate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { blocksContractSigned, tradableContractSigned } =
    useContext(EthersContext)
  const blocks = useAppSelector((state) => state.vaultBlocks.list)
  const selectedBlocks = useAppSelector<number[]>(
    (state) => state.deposit.ids as number[]
  )
  const withdrawStatus = useAppSelector<STATUS>(
    (state) => state.withdraw.withdrawStatus
  )
  const balance = useAppSelector((state) => state.deposit.balanceFormatted)
  const principal = useAppSelector((state) => state.deposit.principal)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const hasOutOfRangeError = useMemo(
    () => selectedBlocks.length >= principal && selectedBlocks.length < balance,
    [balance, principal, selectedBlocks.length]
  )
  const blocksLimit = useMemo(
    () => (balance < BLOCKS_SELECTED_LIMIT ? balance : BLOCKS_SELECTED_LIMIT),
    [balance]
  )

  const onSelect = useCallback(
    (id) => () => {
      if (selectedBlocks.includes(id)) {
        dispatch(depositSlice.actions.remove(id))
        return
      }
      if (
        selectedBlocks.length >= balance ||
        selectedBlocks.length >= blocksLimit
      )
        return
      dispatch(depositSlice.actions.add({ id }))
    },
    [balance, blocksLimit, dispatch, selectedBlocks]
  )

  const onWithdrawConfirm = useCallback(
    (donation: number) => () => {
      dispatch(
        fetchWithdraw({
          contract: tradableContractSigned,
          blocks: selectedBlocks,
          donation,
        })
      )
    },
    [dispatch, selectedBlocks, tradableContractSigned]
  )

  useEffect(() => {
    if (isOpen && selectedBlocks.length === 0) {
      onClose()
    }
  }, [isOpen, onClose, selectedBlocks.length])

  useEffect(() => {
    if (selectedBlocks.length < 1) return
    if (withdrawStatus === STATUS.SUCCESS) {
      toast({
        title: 'Withdraw approved',
        description: `As soon as your transaction is confirmed, you will have them in your wallet.`,
        status: 'success',
        duration: 10000,
        isClosable: true,
      })
      onClose()
      dispatch(depositSlice.actions.clear())
      dispatch(withdrawSlice.actions.clear())
      return
    }
    if (withdrawStatus === STATUS.PENDING) {
      toast({
        title: 'Withdraw',
        description: 'Approve the transaction on metamask',
        status: 'info',
        duration: 3000,
        isClosable: true,
      })
      return
    }
    if (withdrawStatus === STATUS.ERROR) {
      toast({
        title: 'Withdraw',
        description: 'A error happened. Check your wallet and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      dispatch(withdrawSlice.actions.clearError())
    }
  }, [withdrawStatus, dispatch, onClose, selectedBlocks.length, toast])

  useEffect(() => {
    dispatch(
      fetchVaultBlocks({
        contract: blocksContractSigned,
        userAddress: TRADABLE_CORE_ADDRESS,
      })
    )
  }, [blocksContractSigned, dispatch])

  useEffect(() => {
    return () => {
      dispatch(depositSlice.actions.clear())
    }
  }, [dispatch])

  return (
    <>
      {navRef && (
        <>
          <Stack py={10} px={8} spacing={10}>
            <StackItem textAlign={'center'}>
              <Heading size={'lg'}>
                Select the Blocks you want withdraw from TB Vault
              </Heading>
            </StackItem>
            <StackItem>
              <BlocksList
                blocks={blocks}
                onSelectBlock={onSelect}
                selectedBlocks={selectedBlocks}
              />
              <Portal containerRef={navRef}>
                <Collapse in={selectedBlocks.length > 0} animateOpacity>
                  <Box bgColor={'blackAlpha.900'} py={4}>
                    <Box
                      maxW={'7xl'}
                      mx="auto"
                      textAlign={'right'}
                      px={{ base: '6', md: '8' }}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Text
                        textColor={!hasOutOfRangeError ? 'white' : 'brand.500'}
                      >
                        {selectedBlocks.length}/{blocksLimit} Selected Blocks
                        {hasOutOfRangeError && (
                          <Text as={'span'} textColor={'brand.500'}>
                            {' '}
                            - Must be smaller than{' '}
                            <b>Deposited ({principal - 1}) Blocks</b> or equal{' '}
                            <b>Current (Deposited+Profit) ({balance})</b>
                          </Text>
                        )}
                      </Text>
                      <Button
                        ml={4}
                        onClick={onOpen}
                        disabled={hasOutOfRangeError}
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>
                </Collapse>
              </Portal>
            </StackItem>
          </Stack>
          <CartDrawer
            blocksUser={selectedBlocks}
            onClose={onClose}
            onRemove={onSelect}
            isOpened={isOpen}
            onConfirm={onWithdrawConfirm}
            status={withdrawStatus}
            isButtonDisabled={hasOutOfRangeError}
            title={`Withdrawing ${selectedBlocks.length} Blocks`}
            confirmButtonText={'Confirm withdraw'}
            dialogText={
              <>
                <HStackItemList>
                  The rule is first come, first served. Be sure the Blocks are
                  on TB Vault, and you execute your transaction quickly.
                  Unfortunately, we can't help with gas fee losses with failed
                  transactions.
                </HStackItemList>
              </>
            }
          />
        </>
      )}
    </>
  )
}
