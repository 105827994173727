import { useContext } from 'react'
import { ReportIssue } from 'components/organisms/ReportIssue'
import GlobalContext from 'contexts/GlobalContext'
import { HowTradeWorks } from 'components/organisms/HowTradeWorks'
import { TradeBlocks } from 'components/organisms/TradeBlocks'
import { useWallet } from 'hooks/useWallet'

export const Trade = () => {
  const { footerRef } = useContext(GlobalContext)
  const { moveIsApproved, onMoveApproval } = useWallet()

  return (
    <>
      <HowTradeWorks />
      <TradeBlocks
        isMoveApproved={moveIsApproved}
        onMoveApproval={onMoveApproval}
        navRef={footerRef}
      />
      <ReportIssue />
    </>
  )
}
