import EthersContext from 'contexts/EtherInstances'
import { useContext, useEffect } from 'react'
import { fetchDepositInfo } from 'services/deposit'
import { useAppDispatch, useAppSelector } from './redux'

export const useDepositInfo = () => {
  const { tradableContractSigned, userAddress } = useContext(EthersContext)
  const dispatch = useAppDispatch()
  const depositInfo = useAppSelector(({ deposit }) => ({
    balance: deposit.balance,
    balanceFormatted: deposit.balanceFormatted,
    principal: deposit.principal,
    principalTotal: deposit.principalTotal,
  }))

  useEffect(() => {
    dispatch(
      fetchDepositInfo({ contract: tradableContractSigned, userAddress })
    )
  }, [dispatch, tradableContractSigned, userAddress])

  return depositInfo
}
