import { useState } from 'react'
import { Box, Link, SimpleGrid, SimpleGridProps, Text } from '@chakra-ui/layout'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/image'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react'
import hexToRgba from 'hex-to-rgba'
import { BLOCKS_URL } from 'base/dotenv'
import { getOpenSeaUrl } from 'utils/getUrl'
import theme from 'base/theme'

export interface BlocksListType extends SimpleGridProps {
  blocks: number[]
  onSelectBlock: Function
  selectedBlocks: number[]
  preview?: boolean
}

export const BlocksList = ({
  blocks,
  onSelectBlock,
  selectedBlocks,
  preview = false,
  ...props
}: BlocksListType) => {
  const [image, setImage] = useState<number | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <SimpleGrid
      columns={{ base: 3, md: 5, lg: 8 }}
      gap={{ base: '4', md: '6', lg: '6' }}
      {...props}
    >
      {blocks.map((blockId) => (
        <Box
          key={blockId}
          position={'relative'}
          overflow={'hidden'}
          _hover={{ cursor: 'pointer' }}
        >
          {preview && <Text textAlign={'center'}>#{blockId}</Text>}
          <Image
            onClick={
              preview
                ? () => {
                    setImage(blockId)
                    onOpen()
                  }
                : onSelectBlock(blockId)
            }
            key={blockId}
            src={`${BLOCKS_URL}/${blockId}_ps${preview ? 0 : 4}.png`}
            alt={`Block #${blockId}`}
          />
          {!preview && selectedBlocks.includes(blockId) && (
            <Box
              bgColor={hexToRgba(theme.colors.brand['100'], '0.8')}
              position={'absolute'}
              left="0"
              right="0"
              top="0"
              bottom="0"
              zIndex="2"
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              onClick={onSelectBlock(blockId)}
            >
              <CheckCircleIcon color={'brand.900'} boxSize="2em" />
            </Box>
          )}
          {preview && (
            <Box
              textAlign={'center'}
              display={'flex'}
              justifyContent={'space-between'}
              px="1"
              fontSize={'sm'}
            >
              <Link href={getOpenSeaUrl(blockId, 'blocks')} target={'_blank'}>
                OpenSea
              </Link>
              <Link onClick={onSelectBlock(blockId)}>Remove Block</Link>
            </Box>
          )}
        </Box>
      ))}
      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>#{image}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image
              src={`${BLOCKS_URL}/${image}_ps0.png`}
              alt={`Block #${image}`}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </SimpleGrid>
  )
}
