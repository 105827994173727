import { Box, Heading, List, ListIcon, ListItem } from '@chakra-ui/react'
import { AiOutlineRightSquare } from 'react-icons/ai'

export const HowTradeWorks = () => {
  return (
    <Box my={20} id="howTradeWorks">
      <Heading size="2xl" textAlign={'center'}>
        How does it works?
      </Heading>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        maxW={'container.lg'}
        px="5"
        mx={'auto'}
        mt={'10'}
      >
        <List spacing={3} textAlign={'left'} w="60%" mx="auto">
          <ListItem>
            <ListIcon as={AiOutlineRightSquare} color="brand.300" />
            Select the Blocks you want from TB Vault on the left side.
          </ListItem>
          <ListItem>
            <ListIcon as={AiOutlineRightSquare} color="brand.300" />
            Select the Blocks you want to give to TB Vault. Remember, it needs
            to be 1 more than the wanted selection. 1 Block is the fee.
          </ListItem>
          <ListItem>
            <ListIcon as={AiOutlineRightSquare} color="brand.300" />
            You can select 12 Blocks from TB Vault and 13 from your wallet. 25
            Blocks max per transaction.
          </ListItem>
          <ListItem>
            <ListIcon as={AiOutlineRightSquare} color="brand.300" />
            Confirm all steps until you get your wallet opened to confirm the
            transaction.
          </ListItem>
        </List>
      </Box>
    </Box>
  )
}
