import { useToast } from '@chakra-ui/react'
import { createContext, useCallback, useEffect, useState } from 'react'
import { BlocksCoreContract } from 'web3/contracts/blocksCore'
import { Provider } from 'web3/contracts/provider'
import { TradableContract } from 'web3/contracts/tradable'

const providerClass = new Provider()
const blocksCoreContract = new BlocksCoreContract(providerClass.get())
const tradableContract = new TradableContract(providerClass.get())

const DEFAULT_CONTEXT = {
  provider: providerClass.get(),
  blocksContractRead: blocksCoreContract.get(),
  tradableContractRead: tradableContract.get(),
  blocksContractSigned: blocksCoreContract.get(),
  tradableContractSigned: tradableContract.get(),
  walletIsConnected: false,
  userAddress: '',
  onConnectWallet: () => {},
  connectWalletHandler: () => {},
}

const EthersContext = createContext(DEFAULT_CONTEXT)

export const EthersContextProvider = ({ children }) => {
  const [provider, setProvider] = useState(DEFAULT_CONTEXT.provider)
  const [userAddress, setUserAddress] = useState(DEFAULT_CONTEXT.userAddress)
  const [blocksContractSigned, setBlocksContractSigned] = useState(
    DEFAULT_CONTEXT.blocksContractSigned
  )
  const [tradableContractSigned, setTradableContractSigned] = useState(
    DEFAULT_CONTEXT.tradableContractSigned
  )
  const [walletIsConnected, setWalletIsConnected] = useState(
    DEFAULT_CONTEXT.walletIsConnected
  )
  const toast = useToast()

  const connectWalletHandler = useCallback(async () => {
    if (walletIsConnected) return
    await providerClass.connectWallet()
    const connectedWallet = providerClass.getConnectedWallet()
    const signer = await connectedWallet.getSigner()
    const address = await signer.getAddress()
    const blocksSigned = new BlocksCoreContract(signer)
    const tradableBlocksSigned = new TradableContract(signer)

    setProvider(connectedWallet)
    setUserAddress(address)
    setBlocksContractSigned(blocksSigned.get())
    setTradableContractSigned(tradableBlocksSigned.get())
    setWalletIsConnected(true)
  }, [walletIsConnected])

  const onConnectWallet = useCallback(async () => {
    try {
      await connectWalletHandler()
      toast({
        title: 'Connect a wallet',
        description: 'Wallet connected successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      console.log('Error onConnectWallet', error)
      toast({
        title: 'Connect a wallet',
        description: 'Error while connecting your wallet. Try again late.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [connectWalletHandler, toast])

  useEffect(() => {
    async function checkWalletConnection() {
      if (typeof window.ethereum !== 'undefined') {
        const addressList = await window.ethereum?.request({
          method: 'eth_accounts',
        })
        if (addressList.length > 0) {
          connectWalletHandler()
        }
      }
    }
    checkWalletConnection()
  }, [connectWalletHandler])

  return (
    <EthersContext.Provider
      value={{
        ...DEFAULT_CONTEXT,
        provider,
        blocksContractSigned,
        tradableContractSigned,
        walletIsConnected,
        onConnectWallet,
        userAddress,
        connectWalletHandler,
      }}
    >
      {provider ? children : ''}
    </EthersContext.Provider>
  )
}

export default EthersContext
